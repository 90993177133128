<template>
    <section class="feature-1">
        <div class="container py-5">
            <div class="row">
                <div class="col-md-4 mb-3">
                    <h1 class="text-center font-weight-bold mb-3"
                        data-aos="fade-down"
                        data-aos-duration="1000">
                        內陰收緊
                    </h1>
                    <div class="img-wrapper d-flex justify-content-center align-items-center mb-4 p-2"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature1/feat1.svg">
                    </div>

                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        膠原自生，增厚內壁
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        緊緻鬆弛私密，增強彈性
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        提升敏感度
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        改善乾澀
                    </h5>
                </div>

                <div class="col-md-4 mb-3">
                    <h1 class="text-center font-weight-bold mb-3"
                        data-aos="fade-down"
                        data-aos-duration="1000">
                        外陰修型
                    </h1>
                    <div class="img-wrapper d-flex justify-content-center align-items-center mb-4 p-2"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature1/feat2.png">
                    </div>

                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        膠原自生，緊緻私密輪廓
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        收緊私密處
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        平滑外陰肌膚
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        美白淡色
                    </h5>
                </div>

                <div class="col-md-4 mb-3">
                    <h1 class="text-center font-weight-bold mb-3"
                        data-aos="fade-down"
                        data-aos-duration="1000">
                        改善尿滲
                    </h1>
                    <div class="img-wrapper d-flex justify-content-center align-items-center mb-4 p-2"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature1/feat3.svg">
                    </div>

                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        收緊骨盆底肌，承托盆腔
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        收緊尿道
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        增強周邊肌肉彈性
                    </h5>
                    <h5 class="text-center font-weight-bold"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        增強忍尿控制力
                    </h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "IntimaFeature1"
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";

    @import "./variables.scss";

    .feature-1 {
        background-image: url($background);
        background-color: $background-color;
        color: $text-color;

        .container {
            .img-wrapper {
                & > .img-fluid {
                    width: 200px;
                }
            }

        }
    }
</style>
